import React from 'react'

const Logs = (props) => {
  return (
    <>
      Hello i am a log page
    </>
  )
}

export default Logs
